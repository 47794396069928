import { ChangeEvent, FC, useCallback, useMemo, useState } from "react";
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffectOnceWhen } from "rooks";
import moment from "moment";
import { CalculatedFromToText } from "./CalculatedFromToText";
import {
  Dates,
  DateType,
  DateUnit,
} from "../../../../../../services/cloudchipr.api";
import { generateFromToByPeriod } from "../../../utils/helpers/period/generateFromToByPeriod";
import { ApplyButton } from "../ApplyButton";
import { getAvailableMinDateMessage } from "../../../utils/helpers/getAvailableMinDateMessage";

type PeriodDateUnit = DateUnit | null | undefined;

interface PeriodSelectionProps {
  dateType: DateType;
  selectedValue: string;
  minDate?: string;
  availableMinDate?: string;
  selectedUnit?: Dates["unit"];
  onApply(type: DateType, unit: DateUnit, value: number): void;
}

export const PeriodSelection: FC<PeriodSelectionProps> = ({
  dateType,
  selectedUnit,
  selectedValue,
  onApply,
  minDate,
  availableMinDate,
}) => {
  const [value, setValue] = useState(selectedValue);
  const [unit, setUnit] = useState<PeriodDateUnit>(selectedUnit);

  const calculatedDate = useMemo(() => {
    return generateFromToByPeriod(value, dateType, unit);
  }, [value, unit, dateType]);

  const valueChangeHandler = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (value && +value > 0) {
      setValue(value);
    } else {
      setValue("");
    }
  }, []);

  const unitChangeHandler = useCallback((e: SelectChangeEvent) => {
    setUnit(e.target.value as DateUnit);
  }, []);

  const applyClickHandler = useCallback(() => {
    if (!unit || !value) {
      return;
    }

    const date = moment().subtract(value, unit);
    const isBefore = date.isBefore(minDate);
    const invalid = !moment(date).isValid();

    if (isBefore || invalid) {
      onApply(dateType, unit, moment().diff(moment(minDate), unit));
    } else {
      onApply(dateType, unit, +value);
    }
  }, [onApply, value, unit, dateType, minDate]);

  useEffectOnceWhen(() => {
    setUnit(dateType === "past" ? "day" : "week");
    setValue("1");
  }, !unit);

  useEffectOnceWhen(
    () => {
      setUnit("week");
    },
    unit === "day" && dateType === "last",
  );

  return (
    <Stack py={2} px={1.5}>
      {availableMinDate && (
        <Typography variant="tiny" pb={2}>
          {getAvailableMinDateMessage(availableMinDate)}
        </Typography>
      )}

      <Stack direction="row" alignItems="baseline" spacing={1}>
        <Typography variant="body2" textTransform="capitalize">
          {dateType}
        </Typography>

        <TextField
          size="xsmall"
          type="number"
          value={value}
          onChange={valueChangeHandler}
          sx={{ width: 90, minWidth: 90 }}
          InputProps={{ inputProps: { min: 1 } }}
        />

        <FormControl size="xsmall" sx={{ width: 140 }}>
          <Select value={unit ?? ""} onChange={unitChangeHandler}>
            {dateType === "past" && (
              <MenuItem value="day">{makePlural("Day", value)}</MenuItem>
            )}
            <MenuItem value="week">{makePlural("Week", value)}</MenuItem>
            <MenuItem value="month">{makePlural("Month", value)}</MenuItem>
            <MenuItem value="year">{makePlural("Year", value)}</MenuItem>
          </Select>
        </FormControl>
      </Stack>

      <CalculatedFromToText from={calculatedDate.from} to={calculatedDate.to} />

      <ApplyButton onClick={applyClickHandler} />
    </Stack>
  );
};

const makePlural = (name: string, value: string) =>
  +value > 1 ? `${name}s` : name;
