import { ChartType } from "../../../../../../../../storybook/charts/multi-type-chart/utils/types/types";

export const percentFormater = (value: number | string) =>
  `${(+value).toFixed(0)}%`;

export const getXAxisTickLabelFormater = (
  chartDataLength: number,
  chartType?: ChartType,
  width?: number,
  interval?: number,
) => {
  return (label: string) => {
    if (typeof label !== "string") {
      return;
    }

    if (chartType !== "horizontal_stack_bar") {
      if (!width) {
        return;
      }

      const wordWidth = (width / chartDataLength) * chartEmptyStateBalancer;
      const wordLength = (wordWidth / letterWidth) * ((interval ?? 0) + 1);

      if (wordLength >= label.length) {
        return label;
      }

      return `${label?.substring(0, wordLength)}...`;
    }

    return `${label?.substring(0, 17)}...`;
  };
};
const chartEmptyStateBalancer = 3 / 4;
const letterWidth = 6;

export const getInterval = (chartDataLength: number) =>
  Math.ceil(chartDataLength / maxChartDataLengthForInterval) - 1;
const maxChartDataLengthForInterval = 10;
